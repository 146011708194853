import type { Declaration, Rule } from "css";
import cssParser from "css";

type TFontStyle = { src: string; fontStyle: string; fontWeight: string };
type TFont = { fontFamily: string; styles: TFontStyle[] };

/**
 * Fetches fonts from the Virtualbadge CSS and returns them as an array of fonts with their styles.
 * Font styles are merged based on the same font family.
 * If custom fonts are provided, they are included in the result.
 *
 * @param {number} [retry=0] - The number of retry attempts made.
 * @param {PolotnoDesigner.CustomFont[]} [customFonts=[]] - An array of custom fonts to include.
 * @returns {Promise<TFont[]>} A promise that resolves to an array of fonts with their styles.
 *
 * @example
 * const fonts = await parseFontsFromVirtualbadge();
 *
 * // Result:
 * [
 *   {
 *     fontFamily: 'MyCustomFont',
 *     styles: [
 *       {
 *         src: 'url(pathToNormalFile.ttf)',
 *         fontStyle: 'normal',
 *         fontWeight: 'normal',
 *       },
 *       {
 *         src: 'url(pathToBoldFile.ttf)',
 *         fontStyle: 'normal',
 *         fontWeight: 'bold',
 *       },
 *     ],
 *   },
 *   // ... other fonts
 * ]
 *
 * @throws {Error} If there is an HTTP error or if the CSS file cannot be parsed.
 * @throws {Error} If the fonts cannot be fetched from the Virtualbadge API after multiple retries.
 */
export const parseFontsFromVirtualbadge = async (
    retry = 0,
    customFonts: PolotnoDesigner.CustomFont[] = []
): Promise<TFont[]> => {
    const FONT_URL = "https://static.virtualbadge.io/fonts/fonts.css";
    try {
        const fontsMap = new Map<string, TFont>();

        customFonts.forEach((customFont) => {
            fontsMap.set(customFont.fontFamily, {
                fontFamily: customFont.fontFamily,
                styles: [
                    {
                        src: `url(${customFont.url})`,
                        // Default values, custom fonts do not have fontStyle and fontWeight.
                        fontStyle: "normal",
                        fontWeight: "400",
                    },
                ],
            });
        });

        const response = await fetch(FONT_URL);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const text = await response.text();
        const css = cssParser.parse(text);
        if (!css.stylesheet) {
            throw new Error("Could not parse CSS file");
        }

        css.stylesheet.rules.forEach((rule: Rule) => {
            if (rule.type === "font-face" && rule.declarations) {
                const filteredDeclarations = rule.declarations.filter(
                    (decl): decl is Declaration => decl.type === "declaration"
                );

                const fontFamilyDeclaration = filteredDeclarations.find((decl) => decl.property === "font-family");
                if (!fontFamilyDeclaration || !fontFamilyDeclaration.value) {
                    console.error("Missing font-family in some declarations");
                    return; // Skip to the next rule if fontFamily is undefined.
                }

                const fontFamily = fontFamilyDeclaration.value.replaceAll('"', "");

                const fontStyles = filteredDeclarations
                    .filter((decl) => decl.property === "src")
                    .map((decl) => {
                        const urlMatch = decl.value?.match(/url\((.*?)\)/);
                        if (!urlMatch) {
                            console.error(`Could not parse URL for fontFamily ${fontFamily}`);
                            return null;
                        }

                        // Check if the font has fontStyle and fontWeight.
                        const fontStyleDeclaration = filteredDeclarations.find((d) => d.property === "font-style");
                        const fontWeightDeclaration = filteredDeclarations.find((d) => d.property === "font-weight");

                        if (!fontStyleDeclaration?.value || !fontWeightDeclaration?.value) {
                            console.error(`Missing fontStyle or fontWeight for fontFamily ${fontFamily}`);
                            return null;
                        }

                        return {
                            src: `url(${urlMatch[1]})`,
                            fontStyle: fontStyleDeclaration.value,
                            fontWeight: fontWeightDeclaration.value,
                        };
                    });

                if (!fontsMap.has(fontFamily)) {
                    fontsMap.set(fontFamily, { fontFamily, styles: [] });
                }

                fontStyles.forEach((style) => {
                    if (style) fontsMap.get(fontFamily)?.styles.push(style);
                });
            }
        });

        return Array.from(fontsMap.values());
    } catch (error) {
        console.error("Failed to fetch or parse fonts: ", error);
        if (retry < 3) {
            return parseFontsFromVirtualbadge(retry + 1);
        }
        throw new Error("Could not fetch fonts from Virtualbadge API after retries");
    }
};
