import ReactDOM from "react-dom/client";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";

import { WrappedApp } from "./App";

import "./index.css";

if (process.env.ENVIRONMENT !== "local") {
    const appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: process.env.APPLICATIONINSIGHTS_INSTRUMENTATION_KEY,
        },
    });
    appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
        if (envelope.tags) {
            // eslint-disable-next-line no-param-reassign
            envelope.tags["ai.cloud.role"] = "recipient-view";
            // eslint-disable-next-line no-param-reassign
            envelope.tags["ai.cloud.roleInstance"] = `recipient-view-${process.env.ENVIRONMENT}`;
        }
    });
    window.appInsights = appInsights;
    appInsights.loadAppInsights();
    appInsights.trackPageView();
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<WrappedApp />);
