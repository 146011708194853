/**
 * Indicator to show specific defined logs in the console on production.
 * To enable it, set the local storage variable "DEBUG_CONSOLE" to "true".
 * Enabled by default in non-production environments.
 * Note: console.log, console.warn, and console.debug are removed in production by default and cannot be enabled.
 * @type {boolean}
 * @example if (DEBUG_CONSOLE) console.error("Debug error");
 * @example if (DEBUG_CONSOLE) console.info("Debug info");
 * @example if (DEBUG_CONSOLE) console.table({message: "Debug trace"});
 * @example if (DEBUG_CONSOLE) {
 *      console.group("Debug group");
 *      console.groupCollapsed("Debug groupCollapsed");
 *      console.groupEnd();
 *  }
 */
export const DEBUG_CONSOLE: boolean =
    process.env.ENVIRONMENT !== "production" || localStorage.getItem("DEBUG_CONSOLE") === "true";
